<template>
  <div>
    <el-form
      :model="formInline"
      :rules="rules"
      ref="form"
      label-width="100px"
      style="width: 700px; margin: 0 auto"
    >
      <el-form-item :label="$t('searchModule.Rule_Name')" prop="feeName">
        <el-input
          type="text"
          placeholder="请输入规则名称"
          :maxlength="20"
          v-model.trim="formInline.feeName"
          auto-complete="off"
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <el-form-item label="规则编码" prop="feeCode">
        <el-input
          placeholder="请输入规则编码"
          :maxlength="20"
          v-model.trim="formInline.feeCode"
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
        <el-select
          v-model.trim="formInline.operationId"
          filterable
          :disabled="!!$route.query.feeId"
          size="15"
        >
          <el-option
            :label="value.operationName"
            :value="value.operationId"
            :key="value.operationId"
            v-for="value in tenantList"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 20 }"
          :maxlength="250"
          placeholder="请输入描述"
          v-model="formInline.description"
          style="width: 370px"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    let checkCode = (rule, value, callback) => {
      value = value.replace(/\s/g, "");
      if (/[\u4E00-\u9FA5]/.test(value)) {
        return callback(new Error("编码不能存在汉字"));
      }
      if (!value) {
        callback(new Error("规则编码不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则编码长度在2-50之间"));
      } else {
        callback();
      }
    };
    let checkName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("规则名称不能为空"));
      } else if (value.length < 2 || value.length > 50) {
        callback(new Error("规则名称长度在2-50之间"));
      } else {
        callback();
      }
    };
    return {
      formInline: {
        operationId: '',
      },
      tenantList: [],
      rules: {
        feeName: [
          {
            required: true,
            message: "请输入规则名称",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        feeCode: [
          {
            required: true,
            message: "请输入规则编码",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
        operationId: [
          {
            required: true,
            message: "请输入运营商",
            trigger: "blur",
          }
        ],
      },
    };
  },
  props: ["value"],
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.formInline = newVal;
      },
      immediate: true,
    },
    formInline: {
      handler(newVal, oldVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
  created() {
    this.getTenantList();
  },
  methods: {
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then(res => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc);
        }
      })
    },
    checkForm() {
      let forms = this.$refs.form;
      let flag = true;
      if (Array.isArray(forms)) {
        forms.forEach((form) => {
          form.validate((valid) => {
            if (!valid) {
              flag = false;
            }
          });
        });
      } else {
        forms.validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      // this.$refs.form.validate(fn);
      return flag;
    },
    getValidate() {},
  },
};
</script>
